import { Component, HostBinding, Input } from '@angular/core';
import { Sustainability } from '@app/shared/models/assessment/category-rating.model';
import { IconComponent } from '@app/shared/components/icons/icon.component';

const CLASS_BY_SUSTAINABILITY_RATING: Record<Sustainability, string> = {
    [Sustainability.BAD]: 'negative',
    [Sustainability.NEUTRAL]: 'neutral',
    [Sustainability.GOOD]: 'positive',
    [Sustainability.VERY_GOOD]: 'very-positive',
};

@Component({
    selector: 'app-sustainability-indicator',
    templateUrl: './sustainability-indicator.component.html',
    styleUrls: ['./sustainability-indicator.component.scss'],
    standalone: true,
    imports: [IconComponent],
})
export class SustainabilityIndicatorComponent {

    @Input({ required: true }) sustainabilityRating!: Sustainability;

    @HostBinding('class')
    get iconClass(): string {
        return CLASS_BY_SUSTAINABILITY_RATING[this.sustainabilityRating];
    }

    constructor() {
    }
}
