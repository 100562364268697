import { Routes } from '@angular/router';
import { IsAuthenticatedGuard } from '@app/core/authentication/guards/is-authenticated.guard';
import { APP_TITLE } from '@app/app/services/app-title.service';

const routes: Routes = [
    {
        path: 'manuals',
        loadChildren: () => import('./profile/manuals/manuals.module').then((m) => m.ManualsModule),
        canActivate: [IsAuthenticatedGuard],
    },
    {
        path: 'authentication/migrate-account',
        loadChildren: () => import('./account-migration/account-migration.module').then((m) => m.AccountMigrationModule),
    },
    {
        path: 'introduction',
        loadComponent: () => import('./profile/introduction/introduction.page')
            .then((m) => m.IntroductionPage),
        data: { [APP_TITLE]: 'Wie funktioniert die Zertifizierung' },
    },
    {
        path: 'imprint',
        loadChildren: () => import('./profile/imprint/imprint.module').then((m) => m.ImprintModule),
    },
    {
        path: 'privacy-terms',
        loadChildren: () => import('src/app/profile/privacy-terms/privacy-terms.module').then((m) => m.PrivacyTermsModule),
    },
    {
        path: 'terms',
        loadChildren: () => import('./profile/terms/terms.module').then((m) => m.TermsModule),
    },
];

export default routes;

