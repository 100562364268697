import { ToolbarNavItem } from '@app/shared/components/toolbar-nav/toolbar-nav-item';
import { UserRole } from '@app/shared/models/user-role.model';

export function createNavigationItems(userRole?: UserRole): ToolbarNavItem[] {
    switch (userRole) {
        case UserRole.ADMIN:
            return [
                {
                    label: 'Benutzer',
                    url: '/users',
                },
                {
                    label: 'Export',
                    url: '/export',
                },
                {
                    label: 'Dokumente',
                    url: '/manuals',
                },
            ];

        case UserRole.AUDIT_COMPANY_ADMIN:
            return [
                {
                    label: 'Anfragen',
                    url: '/certification/requests',
                },
                {
                    label: 'Auditoren',
                    url: '/users',
                },
                {
                    label: 'Unternehmensprofil',
                    url: '/audit-company-profile',
                },
                {
                    label: 'Dokumente',
                    url: '/manuals',
                },
            ];

        case UserRole.WINERY_USER:
            return [
                {
                    label: 'Zertifizierung',
                    url: '/certification',
                },
                {
                    label: 'Dokumente',
                    url: '/manuals',
                },
            ];

        case UserRole.AUDITOR:
            return [
                {
                    label: 'Anfragen',
                    url: '/certification/requests',
                },
                {
                    label: 'Dokumente',
                    url: '/manuals',
                },
            ];

        default:
            return [];
    }
}
